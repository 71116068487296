.container_flowchart{
    height: auto;
    width: auto;
    margin-bottom: 350px;
    margin-top: 250px;
}

.image_flowchart{
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
