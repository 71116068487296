.container_casestudy{
    margin: 50px;
    h1{
        font-size: 35px;
        font-weight: 550;
    }
    h3{
        margin-top: 20px;
    }
    p{
        font-weight: 450;
        font-size: 17px;
    }
}



