body {
  font-family: 'Roboto', sans-serif;
  background-color: #f4f4f4;
  color: #333;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.image-container1 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-bottom: 200px; 
    margin-top: 200px; 
    
  }
  
  .zoom-image {
    transition: transform 0.3s ease-in-out;
    max-width:100%;
  }
  
  .zoom-image:hover {
    transform: scale(1.1); /* Adjust the scale factor for the desired zoom level */
  }

  .image-row {
    display: flex;
    justify-content: space-around;
}

.image-container {
    margin: 10px; 
    box-sizing: border-box;
}
.custom-width {
  width: 80vh;
}
.image-website {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}
.image-container img {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}
.image6-container{
  display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-bottom: 200px;
}
.image6-website{
  transition: transform 0.3s ease-in-out;
  max-width:50%;
}
.image7-container{
  display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-bottom: 200px;
}
.image7-website{
  transition: transform 0.3s ease-in-out;
  max-width:100%;
}



@media only screen and (max-width: 768px) {
  .image-row {
      flex-direction: column; /* Stack images on top of each other on small screens */
  }

  .image-container,
  .image-container1,
  .image6-container,
  .image7-container {
      margin: 10px 0; 
  }

  .custom-width {
      width: 100%;
  }

  .image6-website {
      max-width: 80%; 
  }
}

.container-client {
  max-width: 90%;
  margin: 50px auto;
  padding: 30px; 
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); 
  border-radius: 10px; 
  text-align: left;
}

h1 {
  color: #000000; 
   margin-bottom: 20px;
}

p {
  line-height: 31px;
  font-size: 18px;
  color: #454545; /* Darker text color for better contrast */
}

.unique-word {
  font-family: 'Pacifico', cursive; /* Attractive font for the unique word */
  font-size: 45px; /* Font size for the unique word */
  color: #1c576d;/* Unique color for the word */
  font-weight: 700;
  display: inline; /* Ensure each word is on a new line */
}
.larger-word {
  font-size: 40px; 
 
}